<template>
    <div style="width: 100%;height: 100%;overflow: hidden">
        <div v-for="(item,index) in expertLink" :key="index">
            <img :src="item.url" class="low">
        </div>
        <div style="display: flex">
            <div style="width: 100%;height: auto;overflow: hidden" >
                <div style="width: 50%;height:100%;float: left;">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven9.jpg" style="width: 100%;height: 100%;background-size: cover;background-position: center;">
                </div>
                <div class="old" style="width: 50%;float: left">
                    <Introductionlogin></Introductionlogin>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Introductionlogin from "./components/Introductionlogin";
export default {
    components: {
        Introductionlogin

    },

    computed: {

    },
    data () {
        return {
            expertLink:[
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven1.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven2.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven3.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven4.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven5.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven6.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven7.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/seven/seven10.png"},

            ]
        }
    },
    mounted:function(){

    },
    created: function(){
    
    },
    methods:{

    }
}
</script>
<style scoped>
.low {
    width: 100%;
    height: 100%;
}

.old {
    width: 100%;
    height: 100%;
    /*background: url("../../assets/imgs/index/expertConnection_img/expertConnection12.jpg");background-size: 100%;*/
    background: #FFFFFF;
}
</style>
